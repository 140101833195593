<template>
  <UInput 
    :color="color"
    :disabled="disabled"
    :loading="loading"
    :padded="padded"
    :placeholder="placeholder"
    :required="required"
    :size="size"
    :type="type"
    :variant="variant"
    :ui="styles.favoritely"
  >
    <template v-if="$slots.leading" #leading>
      <slot name="leading"/>
    </template>
    <slot/>
    <template v-if="$slots.trailing" #trailing>
      <slot name="trailing"/>
    </template>
    <template v-if="cancellable" #trailing>
      <UButton
        v-show="q !== ''"
        color="gray"
        variant="link"
        icon="i-heroicons-x-mark-20-solid"
        :padded="false"
        @click="q= ''"
      />
    </template>
  </UInput>
</template>

<script setup>
  const styles = { 
    favoritely: { 
      variant: {
        none: 'font-semibold ring-1 ring-[#E4E4E4] '+
              'focus:ring-1 focus:ring-[#f26998] '+
              'dark:ring-[#384247] dark:bg-[#1b2022] '+
              'dark:focus:ring-[#f26998] dark:focus:bg-[#2b3236]'
      },
      icon: {
        trailing: {
          pointer: ''
        }
      }
    } 
  }

  const props = defineProps({
    cancellable: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    padded: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    type: {
      type: String,
      default: 'text'
    },
    variant: {
      type: String,
      default: 'none'
    },
  })

  if (props.cancellable) {
    styles.favoritely['icon'] = { trailing: { pointer: '' }}
  }
</script>